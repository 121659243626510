@if(currentScreenSize === 'xSmall' || currentScreenSize === 'smallOne'){
    <!-- <div class="container-games-widget display-widget-mobile container-xxl">
        <div class="crash-games-widget">
            <div class="box-widget">
                <div class="container-title-widget">
                    <div class="title-widget">Crash Games</div>
                    <a [routerLink]="['/search']"  [queryParams]="{'tags':('CRASH' | enCodeUri)}">
                        <fa-icon class="icon" [icon]="faArrowRight" [size]="'1x'"></fa-icon>
                    </a>
                </div>
                <div class="container-games">
                    <div class="games-right" style="cursor: pointer;">
                        @for (item of listGameCrash; track item) {
                        <a (click)="clickPlayCustom(item)">
                            <img [src]="item['game_image']" [alt]="item['game_name'] | slugName">
                            <span>{{item['game_name']}}</span>
                            <span>{{item['provider_name']}}</span>
                        </a>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <!-- <div class="container-games-widget display-widget-mobile container-xxl full-widget clear">
        <div class="event-games-widget">
            <div class="box-widget">
                <div class="container-title-widget" [ngClass]="listGameEvent.length > 0 ? '':'d-none'">
                    <div class="title-widget">Games Seru Edisi Natal</div>
                    <div class="detail-widget">
                        Mainkan semua game seru spesial natal dan menangkan hadiah events pesial natal
                    </div>
                    <img class="bg-event" [src]="'./../../template/green/assets/images/apk/mobile/background-event-game-collection-mobile.webp'">
                </div>
                <div class="container-games">
                    <div class="list-games">
                        <swiper-container init="false" #swiperGamesEvent class="swiper-container">
                            @for (item of listGameEvent; track item) {
                            <swiper-slide class="swiper-item">
                                <div class="container-card-game" (click)="clickPlayCustom(item)">
                                    <div class="card card-game">
                                        <div class="card-game-top">
                                            @if(item['game_image'] !== '' && item['game_image'] !== undefined) {
                                            <img [ngSrc]="item['game_image']" [alt]="item['game_name']" width="200"
                                                height="200" class="card-img-top" />
                                            } @else() {
                                            <div class="no-image">No Image</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </swiper-slide>
                            }
                        </swiper-container>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="container-games-widget display-widget-mobile container-xxl">
        <div class="slot-games-widget">
            <div class="box-widget">
                <div class="title-widget-box">Popular Slot Game</div>
                <div class="container-title-widget" (click)="filterProvider()">
                    <div class="title-widget">{{currentSlotProvider['name'] ? currentSlotProvider['name'] : 'Semua Povider'}}</div>
                    <a>
                        <fa-icon class="icon" [icon]="faCaretDown" [size]="'1x'"></fa-icon>
                    </a>
                </div>
                <div class="container-games">
                        <div class="list-games">
                            <swiper-container init="false" #swiperSlotGamesM class="swiper-container">
                        
                                @for (item of listGamesSlot; track item) {
                                <swiper-slide class="swiper-item">
                                    <div class="card card-game" (click)="clickPlay(item)">
                                        <div class="card-game-top">
                                            <div class="tags-game">
                                                @for(tag of item['tags']; track tag;){
                                                @if(item['tags'].length > 0 && $index < 3 && tag !=='' ){ <span
                                                    [ngClass]="tag === 'POPULAR'? '': tag === 'NEW'? 'blue-tag': tag === 'BUYIN' || tag === 'LINKWIN' ? 'purple-tag':'green-tag'">
                                                    {{tag | tagName}} </span>
                                                    }
                                                    }
                                            </div>
                                            <div class="ratio ratio-1x1">
                                                @if(item['image'] !== '' && item['image'] !== undefined) {
                                                <img [ngSrc]="item['image']" [alt]="item['title']" width="200" height="200"
                                                    class="card-img-top" />
                                                } @else() {
                                                <div class="no-image">No Image</div>
                                                }
                                            </div>
                                        </div>
                                        <div class="card-title-card" id="{{item._id}}">
                                            {{ item['title'] }}
                                        </div>
                                    </div>
                                </swiper-slide>
                                }
                            </swiper-container>
                        </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-games-widget display-widget-mobile container-xxl full-widget clear">
        <div class="btn-see-all" (click)="clickMenu('SL', true, '')">Lihat Semua Permainan</div>
    </div>
    @if(sourceAPK !== 'apk' && websiteJSONData.configuration.apk_url){
        <div class="container-games-widget display-widget-mobile container-xxl full-widget clear">
            <div class="banner-apk-middle">
                <img class="banner-apk" src="../../../template/green/assets/images/apk/mobile/banner-download-app-mobile.webp">
                <div class="banner-apk-download">
                    <a class="btn-image" [href]="websiteJSONData.configuration.apk_url">
                        <img src="../../../template/green/assets/images/apk/mobile/download-button-android-mobile.webp">
                    </a>
                    <!-- <img class="btn-image" src="../../../template/green/assets/images/apk/mobile/download-button-ios-mobile.webp"> -->
                </div>
            </div>
        </div>
    }
    <!-- <div class="container-games-widget display-widget-mobile container-xxl">
        <div class="show-games-widget">
            <div class="box-widget">
                <div class="container-title-widget">
                    <div class="title-widget">Game Show</div>
                    <a [routerLink]="['/search']"  [queryParams]="{'categories':('LC' | enCodeUri),'tags':('GAMESHOW' | enCodeUri)}">
                        <fa-icon class="icon" [icon]="faArrowRight" [size]="'1x'"></fa-icon>
                    </a>
                </div>
                <div class="container-games">
                    <div class="list-games">
                        <swiper-container init="false" #swiperShowGamesM class="swiper-container">
                            @for (item of listGameShow; track item) {
                            <swiper-slide class="swiper-item w-auto">
                                <div class="container-card-game" (click)="clickPlayCustom(item)">
                                    <div class="card card-game-banner">
                                        <img [ngSrc]="item['slide_images'][0]" [alt]="item['game_name']" width="200" height="200" class="card-img-top" />
                                    </div>
                                    <div class="card card-game">
                                        <div class="card-game-top">
                                            @if(item['game_image'] !== '' && item['game_image'] !== undefined) {
                                            <img [ngSrc]="item['game_image']" [alt]="item['game_name']" width="200" height="200"
                                                class="card-img-top" />
                                            } @else() {
                                            <div class="no-image">No Image</div>
                                            }
                                        </div>
                                        <div class="detail-card">
                                            <span>{{item['game_name']}}</span>
                                            <span>{{item['provider_name']}}</span>
                                        </div>
                                    </div>
                                </div>
                            </swiper-slide>
                            }
                        </swiper-container>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- <div class="container-games-widget display-widget-mobile container-xxl full-widget">
        <div class="arcade-games-widget">
            <div class="box-widget">
                <div class="container-title-widget">
                    <div class="title-widget">ARCADE GAMES</div>
                    <a class="small-text-widget" [routerLink]="['/game/arcade']">
                        see all <fa-icon class="icon" [icon]="faArrowRight" [size]="'xs'"></fa-icon>
                    </a>
                </div>
                <div class="container-games">
                    <div class="list-games">
                        <div class="image-icon">
                            <img id="arcadeIcon" src="../../../../template/green/assets/images/arcade_image_widget.png">
                        </div>
                        <swiper-container init="false" #swiperArcadeGamesM class="swiper-container">
                            @for (item of listGamesArcade; track item) {
                            <swiper-slide class="swiper-item w-auto">
                                <div class="container-card-game">
                                    <div class="card card-game" (click)="clickPlay(item)">
                                        <div class="card-game-top">
                                            <div class="tags-game">
                                                @for(tag of item['tags']; track tag;){
                                                @if(item['tags'].length > 0 && $index < 3 && tag !=='' ){ <span
                                                    [ngClass]="tag === 'POPULAR'? '': tag === 'NEW'? 'blue-tag': tag === 'BUYIN' || tag === 'LINKWIN' ? 'purple-tag':'green-tag'">
                                                    {{tag | tagName}} </span>
                                                    }
                                                    }
                                            </div>
                                            <div class="ratio ratio-1x1">
                                                @if(item['image'] !== '' && item['image'] !== undefined) {
                                                <img [ngSrc]="item['image']" [alt]="item['title']" width="200" height="200" class="card-img-top" />
                                                } @else() {
                                                <div class="no-image">No Image</div>
                                                }
                                            </div>
                                        </div>
                                        <div class="card-title-card" id="{{item._id}}">
                                            {{ item['title'] }}
                                        </div>
                                    </div>
                                </div>
                            </swiper-slide>
                            }
                        </swiper-container>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- <div class="container-games-widget display-widget-mobile container-xxl full-widget">
        <div class="sports-games-widget">
            <div class="image-sports">
                <div class="layer-click">
                    <a [routerLink]="['/game/sportbook']"></a>
                    <a [routerLink]="['/game/esport']"></a>
                </div>
                <img src="../../../../template/green/assets/images/sport.png">
            </div>
        </div>
    </div> -->
}@else{
    <div class="container-games-widget container-xxl">
        <div class="list-containers">
                <!-- <div class="crash-games-widget">
                    <div class="box-widget">
                        <div class="banner-top">
                            <div class="title-widget">CRASH GAMES</div>
                            <img src="../../../../template/green/assets/images/crash-banner-widget.webp">
                        </div>
                        <div class="container-games">
                            <div class="games-right" style="cursor: pointer;">
                                @for (item of listGameCrash; track item) {
                                    <a (click)="clickPlayCustom(item)">
                                        <img [src]="item['game_image']" [alt]="item['game_name'] | slugName">
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="slot-games-widget">
                    <div class="box-widget">
                        <div class="banner-top">
                            <div class="title-widget">SLOT GAMES</div>
                            <img src="../../../../template/green/assets/images/slot-banner-widget.webp">
                        </div>
                        <div class="container-games">
                            <div class="text-top">
                                <div class="navigation-slot-prev" (click)="prevSlot()">< Prev</div>
                                <div class="provider-name">{{slotProviders.providers[slotIndex]['name']}}</div>
                                <div class="navigation-slot-next" (click)="nextSlot()">Next ></div>
                            </div>
                            <div class="list-games">
                                <swiper-container init="false" #swiperSlotGames class="swiper-container">
            
                                    @for (item of listGamesSlot; track item) {
                                        <swiper-slide class="swiper-item w-auto">
                                            <div class="card card-game" (click)="clickPlay(item)">
                                                <div class="card-game-top">
                                                    <div class="tags-game">
                                                        @for(tag of item['tags']; track tag;){
                                                            @if(item['tags'].length > 0 && $index < 3 && tag !=='' ){ 
                                                                <span  [ngClass]="tag === 'POPULAR'? '': tag === 'NEW'? 'blue-tag': tag === 'BUYIN' || tag === 'LINKWIN' ? 'purple-tag':'green-tag'"> {{tag | tagName}} </span>
                                                            }
                                                        }
                                                    </div>
                                                    <div class="ratio ratio-1x1">
                                                        @if(item['image'] !== '' && item['image'] !== undefined) {
                                                        <img [ngSrc]="item['image']" [alt]="item['title']" width="200" height="200" class="card-img-top" />
                                                        } @else() {
                                                        <div class="no-image">No Image</div>
                                                        }
                                                    </div>
                                                </div>
                                                <div class="card-body">
                                                    <div>
                                                        <div class="container-title">
                                                            <div #cardTitleWidget class="h5 card-title" id="{{item._id}}"
                                                                [ngClass]="{'animation-test': cardTitleWidget ? cardTitleWidth > (120 - 32) : false}">{{ item['title'] }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="d-flex card-body-bottom">
                                                    <button>Play</button>
                                                </div>
                                            </div>
                                        </swiper-slide>
                                    }
                                </swiper-container>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="show-games-widget">
                    <div class="box-widget">
                        <div class="banner-top">
                            <div class="title-widget">GAME SHOW</div>
                            <img src="../../../../template/green/assets/images/show-banner-widget.webp">
                        </div>
                        <div class="container-games">
                            <div class="list-games">
                                <swiper-container init="false" #swiperShowGames class="swiper-container">
                            
                                    @for (item of listGameShow; track item) {
                                    <swiper-slide class="swiper-item w-auto">
                                        <div class="card card-game" (click)="clickPlayCustom(item)">
                                            <div class="card-game-top">
                                                <div class="ratio ratio-1x1">
                                                    @if(item['game_image'] !== '' && item['game_image'] !== undefined) {
                                                    <img [ngSrc]="item['game_image']" [alt]="item['title']" width="200" height="200"
                                                        class="card-img-top" />
                                                    } @else() {
                                                    <div class="no-image">No Image</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </swiper-slide>
                                    }
                                </swiper-container>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="arcade-games-widget">
                    <div class="box-widget">
                        <div class="banner-top">
                            <div class="title-widget">ARCADE GAMES</div>
                            <img src="../../../../template/green/assets/images/arcade-banner-widget.webp">
                        </div>
                        <div class="container-games">
                            <div class="text-top">
                                <div class="navigation-slot-prev"(click)="prevArcade()">< Prev</div>
                                <div class="provider-name">{{arcadeProviders.providers[arcadeIndex]['name']}}</div>
                                <div class="navigation-slot-next" (click)="nextArcade()">Next ></div>
                            </div>
                            <div class="list-games">
                                <swiper-container init="false" #swiperArcadeGames class="swiper-container">
                            
                                    @for (item of listGamesArcade; track item) {
                                    <swiper-slide class="swiper-item w-auto">
                                        <div class="card card-game" (click)="clickPlay(item)">
                                            <div class="card-game-top">
                                                <div class="tags-game">
                                                    @for(tag of item['tags']; track tag;){
                                                    @if(item['tags'].length > 0 && $index < 3 && tag !=='' ){ <span
                                                        [ngClass]="tag === 'POPULAR'? '': tag === 'NEW'? 'blue-tag': tag === 'BUYIN' || tag === 'LINKWIN' ? 'purple-tag':'green-tag'">
                                                        {{tag | tagName}} </span>
                                                        }
                                                        }
                                                </div>
                                                <div class="ratio ratio-1x1">
                                                    @if(item['image'] !== '' && item['image'] !== undefined) {
                                                    <img [ngSrc]="item['image']" [alt]="item['title']" width="200" height="200"
                                                        class="card-img-top" />
                                                    } @else() {
                                                    <div class="no-image">No Image</div>
                                                    }
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <div>
                                                    <div class="container-title">
                                                        <div #cardTitle class="h5 card-title"
                                                            [ngClass]="{'animation-test': cardTitle ? cardTitleWidth > (120 - 32) : false}">{{
                                                            item['title'] }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex card-body-bottom">
                                                <button>Play</button>
                                            </div>
                                        </div>
                                    </swiper-slide>
                                    }
                                </swiper-container>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="sports-games-widget desktop">
                    <div class="image-sports">
                        <div class="layer-click">
                            <a [routerLink]="['/game/sportbook']"></a>
                            <a [routerLink]="['/game/esport']"></a>
                        </div>
                        <img src="../../../../template/green/assets/images/sport.png">
                    </div>
                </div> -->
        </div>
        @if(sourceAPK !== 'apk' && websiteJSONData.configuration.apk_url){
            <div class="banner-apk-middle desktop">
                <img class="banner-apk" src="../../../template/green/assets/images/apk/desktop/banner-download-app-desktop.webp">
                <div class="banner-apk-download">
                    <a class="btn-image" (click)="openDialog()">
                        <img src="../../../template/green/assets/images/apk/desktop/download-button-android-desktop.webp">
                    </a>
                    <!-- <img class="btn-image" src="../../../template/green/assets/images/apk/mobile/download-button-ios-mobile.webp"> -->
                </div>
            </div>
        }
    </div>
}
