import { Injectable, Inject, PLATFORM_ID, inject } from '@angular/core';
import { CommonModule, isPlatformBrowser, isPlatformServer, DOCUMENT } from '@angular/common';
import { DataService } from './data.service';
import { Meta, Title } from '@angular/platform-browser';
import { Domain } from '../schema/interface.global';
import { ActivatedRoute, ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { SlugNamePipe } from '@componentpath/pipes/slug-name.pipe';
@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(
    private dataService: DataService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private doc: Document,
    private route : ActivatedRoute,
    private meta: Meta,
    private title: Title,
    private cookies: SsrCookieService
  ) {}

  toTitleCase(phrase:string):string {
    return phrase
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  setMetaWebsite(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {

    let websiteData = this.dataService.websiteJSONData;
    if (
      !this.dataService.websiteJSONData ||
      !this.dataService.websiteJSONData['header']
    ) {
      return;
    }

    const header = this.dataService.websiteJSONData['header'];

    // var iframe = this.doc.createElement('iframe');
    // iframe.src = JSON.parse(this.dataService.websiteJSONData['script']['custom_code']['javascript']);
    // this.doc.write(this.dataService.websiteJSONData['script']['custom_code']['javascript'])
    // this.doc.body.appendChild(this.dataService.websiteJSONData['script']['custom_code']['javascript']);

    if (header['title']) {
      this.setTitle(header['title']);
    }

    this.meta.updateTag({ name: 'categories', content: 'website'});
    this.meta.updateTag({ name: 'theme-color', content: '#111111' });
    this.meta.updateTag({ name: 'language', content: 'id_ID' });
    this.meta.updateTag({ name: 'geo.region', content: 'indonesia' });
    this.meta.updateTag({ name: 'geo.placename', content: 'jakarta' });

    if (header['meta']['description']) {
      this.meta.updateTag({ name: 'description', content: header['meta']['description'] });
    } else {
      this.meta.updateTag({ name: 'description', content: "Slot Demo Pragmatic, Slot Bonus New Member, Situs Slot Terbaru dan Terpercaya " + header['title'] + ", Slot Deposit Pulsa" });
    }
    
    if (header['meta']['keywords']) {
      this.meta.updateTag({ name: 'keywords', content: header['meta']['keywords'] });
    }
    
    this.meta.updateTag({ property: 'og:locale', content: 'id_ID' });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'twitter:card', content: 'summary' });
    if (websiteData['domain']) {
      // console.log(websiteData['domain']);
      this.meta.updateTag({ property: 'og:url', content: 'https://'+this.doc.location.host});
    }
    if (header['title']) {
      // console.log(header['title'].replace(/ .*/, ''))
      let brand = header['title'].replace(/ .*/, '');
      this.meta.updateTag({ property: 'og:site_name', content: brand });
      this.meta.updateTag({ property: 'og:title', content: header['title'] });
    }
    this.meta.updateTag({ property: 'og:image:alt', content: 'slot,demo slot,slot gacor,slot gacor hari ini,slot online,situs slot,slot terbaru' });
    this.meta.updateTag({ property: 'twitter:image:alt', content: 'slot,demo slot,slot gacor,slot gacor hari ini,slot online,situs slot,slot terbaru' });
    
    if (header['meta']['description']) {
      this.meta.updateTag({ property: 'og:description', content: header['meta']['description'] });
    } else{
      this.meta.updateTag({ property: 'og:description', content: "Slot Demo Pragmatic, Slot Bonus New Member, Situs Slot Terbaru dan Terpercaya "+header['title']+", Slot Deposit Pulsa" });
    }
    if (header['logo']) {
      this.meta.updateTag({ property: 'og:image', content: header['logo'] });
      this.meta.updateTag({ property: 'og:image:secure_url', content: header['logo'] });
    }
    this.meta.updateTag({ property: 'og:image:type', content: 'image/webp' });
    this.meta.updateTag({ property: 'og:image:width', content: '300' });
    this.meta.updateTag({ property: 'og:image:height', content: '300' });
    // if (header['meta']['owner']) {
    //   this.meta.updateTag({ name: 'owner', content: header['meta']['owner'] });
    // }
    //hardcode -- remove soon
    // this.meta.updateTag({ name: 'robots', content: 'noindex' });
    // this.meta.updateTag({ name: 'googlebot', content: 'noindex' });
    //end hardcode -- remove soon

    this.addCanonicalAMP(websiteData['domain']);
    if(websiteData['domain']){
      this.updateCanonicalUrl(websiteData['header']['meta']['canonical'] as string, websiteData['domain']);
      this.addRobotNoIndex(websiteData['domain']);
    }

    if (header['favicon']) {
      this.setFavicon(header['favicon']);
    }

    if(this.dataService.websiteConfigData.schema){
      this.createSchema(next, state);
    }

  }

  createSchema(next :ActivatedRouteSnapshot,  state: RouterStateSnapshot){
    // console.log(next);
    // console.log(state);
    if (next.queryParams['providers']){
      // console.log(JSON.parse(decodeURIComponent(next.queryParams['providers']))[0]);
    }
    let providerData = this.dataService.providerJSONData['records'];
    // console.log(providerData);
    let websiteData = this.dataService.websiteJSONData;
    const header = this.dataService.websiteJSONData['header'];

//     {
//   "@context": "https://schema.org",
//   "@type": "Brand",
//   "@id": "abc",
//   "additionalType": "https://google.com",
//   "alternateName": "text",
//   "description": "text",
//   "disambiguatingDescription": "text",
//   "identifier": "12312",
//   "image": "1231",
//   "logo": "1231",
//   "mainEntityOfPage": "1231",
//   "name": "1231",
//   "sameAs": "12312",
//   "slogan": "1231",
//   "url": "1231"
// }

    let elementSchema = this.doc.getElementById('script-schema');

    // let scriptBrand = this.doc.createElement('script');
    // scriptBrand.type = 'application/ld+json';
    // scriptBrand.id = 'script-schema'

    // this.doc.head.appendChild(scriptBrand);

    if (next.url.length === 0 || next.url[0]?.path === 'deposit' || next.url[0]?.path === 'withdraw' || next.url[0]?.path === 'profile' || next.url[0]?.path === 'promotion' || next.url[0]?.path === 'event' || next.url[0]?.path === 'login' || next.url[0]?.path === 'register') {
      if (!next.params['type'] && !next.params['name']) {
        // elementSchema?.remove();
        if (elementSchema) {
          elementSchema.innerHTML =
            `{ 
              "@context": "https://schema.org",
                "@graph": [
                  {
                    "@type": "WebSite",
                    "@id": "https://${this.doc.location.host}${this.doc.location.pathname}#website",
                    "description": "Slot Demo Pragmatic, Slot Bonus New Member, Situs Slot Terbaru dan Terpercaya ${header['title']}, Slot Deposit Pulsa",
                    "publisher": {
                      "@id": "https://${this.doc.location.host}${this.doc.location.pathname}#organization"
                    },
                    "potentialAction": {
                      "@type": "SearchAction",
                       "target": [
                        {
                          "@type": "EntryPoint",
                          "urlTemplate": "https://${this.doc.location.host}/search?value={search_term_string}"
                        }
                      ],
                      "query": "required"
                    },
                    "inLanguage": "id-ID",
                    "image": "${header['logo']}",
                    "mainEntityOfPage": "https://${this.doc.location.host}${this.doc.location.pathname}",
                    "name": "${header['title']}",
                    "url": "https://${this.doc.location.host}${this.doc.location.pathname}"
                  },
                  {
                    "@type": "Organization",
                    "@id": "https://${this.doc.location.host}${this.doc.location.pathname}#organization",
                    "name": "${header['title']}",
                    "url": "https://${this.doc.location.host}${this.doc.location.pathname}",
                    "logo": {
                        "@type": "ImageObject",
                        "inLanguage": "id-ID",
                        "@id": "https://${this.doc.location.host}${this.doc.location.pathname}#/schema/logo/image/",
                        "url": "${header['logo']}",
                        "contentUrl": "${header['logo']}",
                        "width": 1024,
                        "height": 1024,
                        "caption": "${header['title']}"
                    }
                  }
                ]
          }`;
          // this.doc.head.appendChild(scriptBrand);
        }
      }
    }

    if(next.url[0]?.path === 'game' || next.url[0]?.path === 'provider'){
      console.log(this.doc.location)
      if(next.params['name']){
        let slugProvider = new SlugNamePipe();
        const isProvider = providerData.find((value: any) => slugProvider.transform(value.name) === next.params['name']);
        // console.log(isProvider);
        // elementSchema?.remove();
        if (elementSchema) {
          elementSchema.innerHTML =
        //   `{ 
        //     "@context": "https://schema.org",
        //       "@type": "Brand",
        //       "@id": "${isProvider.code}",
        //       "additionalType": "Product",
        //       "alternateName": "${isProvider.name}",
        //       "description": "${isProvider.name}, Situs Slot Terbaru dan Terpercaya ${header['title']}",
        //       "disambiguatingDescription": "${isProvider.name}, Situs Slot Terbaru dan Terpercaya ${header['title']}",
        //       "identifier": "${isProvider.code}",
        //       "image": "${isProvider.image_url}",
        //       "logo": "${isProvider.image_url}",
        //       "mainEntityOfPage": "https://${this.doc.location.host}${this.doc.location.pathname}",
        //       "name": "${isProvider.name}",
        //       "url": "https://${this.doc.location.host}${this.doc.location.pathname}"
        // }`;
          `{
            "@context": "https://schema.org",
            "@graph": [
                  {
                    "@type": "WebSite",
                    "@id": "https://${this.doc.location.host}${this.doc.location.pathname}#website",
                    "description": "Slot Demo Pragmatic, Slot Bonus New Member, Situs Slot Terbaru dan Terpercaya ${header['title']}, Slot Deposit Pulsa",
                    "publisher": {
                      "@id": "https://${this.doc.location.host}${this.doc.location.pathname}#organization"
                    },
                    "potentialAction": {
                      "@type": "SearchAction",
                       "target": [
                        {
                          "@type": "EntryPoint",
                          "urlTemplate": "https://${this.doc.location.host}/search?value={search_term_string}"
                        }
                      ],
                      "query": "required"
                    },
                    "inLanguage": "id-ID",
                    "image": "${header['logo']}",
                    "mainEntityOfPage": "https://${this.doc.location.host}${this.doc.location.pathname}",
                    "name": "${header['title']}",
                    "url": "https://${this.doc.location.host}${this.doc.location.pathname}"
                  },
                  {
                    "@type": "Organization",
                    "@id": "https://${this.doc.location.host}${this.doc.location.pathname}#organization",
                    "name": "${header['title']}",
                    "url": "https://${this.doc.location.host}${this.doc.location.pathname}",
                    "logo": {
                        "@type": "ImageObject",
                        "inLanguage": "id-ID",
                        "@id": "https://${this.doc.location.host}${this.doc.location.pathname}#/schema/logo/image/",
                        "url": "${header['logo']}",
                        "contentUrl": "${header['logo']}",
                        "width": 1024,
                        "height": 1024,
                        "caption": "${header['title']}"
                    }
                  },
                  {
                    "@type": "Product",
                    "brand": {
                      "@type": "Brand",
                      "name":"https://${this.doc.location.host}/game/${next.params['type']}"
                    },
                    "sku": "${isProvider.code}",
                    "description": "${isProvider.name}, Situs Slot Terbaru dan Terpercaya ${header['title']}",
                    "image": "${isProvider.image_url}",
                    "name": "${isProvider.name}",
                    "mpn":"${isProvider.code}",
                    "offers": {
                      "@type": "Offer",
                      "url":"https://${this.doc.location.host}${this.doc.location.pathname}",
                      "availability": "https://schema.org/InStock",
                      "price": "200",
                      "priceCurrency": "IDR"
                    },
                    "review": [],
                    "aggregateRating": {
                      "@type": "AggregateRating",
                      "ratingValue": "5",
                      "reviewCount": "1"
                    }
                  }
                ]
          }`;
        // this.doc.head.appendChild(scriptBrand);
        }
      }

      if(next.params['type'] && !next.params['name']){
        // elementSchema?.remove();
        if (elementSchema) {
          let textParamType = this.toTitleCase((next.params['type']).replace('-', ' '));
          // console.log(textParamType);
          elementSchema.innerHTML =
          `{
            "@context": "https://schema.org",
            "@graph": [
                  {
                    "@type": "WebSite",
                    "@id": "https://${this.doc.location.host}${this.doc.location.pathname}#website",
                    "description": "Slot Demo Pragmatic, Slot Bonus New Member, Situs Slot Terbaru dan Terpercaya ${header['title']}, Slot Deposit Pulsa",
                    "publisher": {
                      "@id": "https://${this.doc.location.host}${this.doc.location.pathname}#organization"
                    },
                    "potentialAction": {
                      "@type": "SearchAction",
                       "target": [
                        {
                          "@type": "EntryPoint",
                          "urlTemplate": "https://${this.doc.location.host}/search?value={search_term_string}"
                        }
                      ],
                      "query": "required"
                    },
                    "inLanguage": "id-ID",
                    "image": "${header['logo']}",
                    "mainEntityOfPage": "https://${this.doc.location.host}${this.doc.location.pathname}",
                    "name": "${header['title']}",
                    "url": "https://${this.doc.location.host}${this.doc.location.pathname}"
                  },
                  {
                    "@type": "Organization",
                    "@id": "https://${this.doc.location.host}${this.doc.location.pathname}#organization",
                    "name": "${header['title']}",
                    "url": "https://${this.doc.location.host}${this.doc.location.pathname}",
                    "logo": {
                        "@type": "ImageObject",
                        "inLanguage": "id-ID",
                        "@id": "https://${this.doc.location.host}${this.doc.location.pathname}#/schema/logo/image/",
                        "url": "${header['logo']}",
                        "contentUrl": "${header['logo']}",
                        "width": 1024,
                        "height": 1024,
                        "caption": "${header['title']}"
                    }
                  },
                  {
                    "@type": "Brand",
                    "@id": "https://${this.doc.location.host}${this.doc.location.pathname}",
                    "description": "${textParamType} Demo, ${textParamType} Bonus New Member, Situs Slot Terbaru dan Terpercaya, Situs ${textParamType} Terbaru dan Terpercaya ${header['title']}, Deposit Pulsa",
                    "disambiguatingDescription": "${textParamType} Demo, ${textParamType} Bonus New Member, Situs Slot Terbaru dan Terpercaya, Situs ${textParamType} Terbaru dan Terpercaya ${header['title']}, Deposit Pulsa",
                    "image": "${header['logo']}",
                    "logo": "${header['logo']}",
                    "mainEntityOfPage": "https://${this.doc.location.host}${this.doc.location.pathname}",
                    "name": "${textParamType} - ${header['title']}",
                    "url": "https://${this.doc.location.host}${this.doc.location.pathname}"
                  }
                ]
              
          }`;
        // this.doc.head.appendChild(scriptBrand);
        this.meta.updateTag({ property: 'og:title', content: textParamType+' - '+header['title'] });
        this.meta.updateTag({ property: 'og:description', content: textParamType+' Demo, '+ textParamType +' Bonus New Member, Situs Slot Terbaru dan Terpercaya, Situs '+ textParamType+' Terbaru dan Terpercaya '+header['title']+', Deposit Pulsa' });
        }

      }
    }
    if (next.url[0]?.path === 'promotion') {
      if (next.queryParams['part']){
        if (this.dataService.promoJSONData['general']) {
          for (const value of this.dataService.promoJSONData['general'] as any[]) {
            if (value._id == next.queryParams['part']) {
              if (elementSchema) {
                elementSchema.innerHTML =
                  `{
                  "@context": "https://schema.org/",
                  "@graph": [
                  {
                    "@type": "WebSite",
                    "@id": "https://${this.doc.location.host}${this.doc.location.pathname}#website",
                    "description": "Slot Demo Pragmatic, Slot Bonus New Member, Situs Slot Terbaru dan Terpercaya ${header['title']}, Slot Deposit Pulsa",
                    "publisher": {
                      "@id": "https://${this.doc.location.host}${this.doc.location.pathname}#organization"
                    },
                    "potentialAction": {
                      "@type": "SearchAction",
                       "target": [
                        {
                          "@type": "EntryPoint",
                          "urlTemplate": "https://${this.doc.location.host}/search?value={search_term_string}"
                        }
                      ],
                      "query": "required"
                    },
                    "inLanguage": "id-ID",
                    "image": "${header['logo']}",
                    "mainEntityOfPage": "https://${this.doc.location.host}${this.doc.location.pathname}",
                    "name": "${header['title']}",
                    "url": "https://${this.doc.location.host}${this.doc.location.pathname}"
                  },
                  {
                    "@type": "Organization",
                    "@id": "https://${this.doc.location.host}${this.doc.location.pathname}#organization",
                    "name": "${header['title']}",
                    "url": "https://${this.doc.location.host}${this.doc.location.pathname}",
                    "logo": {
                        "@type": "ImageObject",
                        "inLanguage": "id-ID",
                        "@id": "https://${this.doc.location.host}${this.doc.location.pathname}#/schema/logo/image/",
                        "url": "${header['logo']}",
                        "contentUrl": "${header['logo']}",
                        "width": 1024,
                        "height": 1024,
                        "caption": "${header['title']}"
                    }
                  },
                  {
                    "@type": "Article",
                    "author": {
                      "@type": "Organization",
                      "name": "${header['title']}"
                    },
                    "headline": "${value.title}",
                    "publisher": {
                      "@type": "Organization",
                      "name": "${header['title']}"
                    },
                    "image": "${value.banner_url}",
                    "datePublished": "${value.created_at}",
                    "dateModified": "${value.updated_at}",
                    "mainEntityOfPage": "https://${this.doc.location.host}${this.doc.location.pathname}",
                    "name": "${value.title}",
                    "description": "${value.title}",
                    "about": {
                      "@type": "Event",
                      "name": "${value.title}"
                    },
                    "contentReferenceTime": "${value.updated_at}"
                  }
                ]
                }`;
              }
              this.meta.updateTag({ property: 'og:title', content: value.title  });
              this.meta.updateTag({ property: 'og:image', content: value.banner_url });
              this.meta.updateTag({ property: 'og:image:secure_url', content: value.banner_url });
            }
          }
        }
        
      }
      
    }
    if (next.url[0]?.path === 'event') {
      if (next.queryParams['part']){
        if (this.dataService.promoJSONData['general']) {
          for (const value of this.dataService.promoJSONData['general'] as any[]) {
            if (value._id == next.queryParams['part']) {
              if (elementSchema) {
                elementSchema.innerHTML =
                  `{
                  "@context": "https://schema.org/",
                  "@graph": [
                  {
                    "@type": "WebSite",
                    "@id": "https://${this.doc.location.host}${this.doc.location.pathname}#website",
                    "description": "Slot Demo Pragmatic, Slot Bonus New Member, Situs Slot Terbaru dan Terpercaya ${header['title']}, Slot Deposit Pulsa",
                    "publisher": {
                      "@id": "https://${this.doc.location.host}${this.doc.location.pathname}#organization"
                    },
                    "potentialAction": {
                      "@type": "SearchAction",
                       "target": [
                        {
                          "@type": "EntryPoint",
                          "urlTemplate": "https://${this.doc.location.host}/search?value={search_term_string}"
                        }
                      ],
                      "query": "required"
                    },
                    "inLanguage": "id-ID",
                    "image": "${header['logo']}",
                    "mainEntityOfPage": "https://${this.doc.location.host}${this.doc.location.pathname}",
                    "name": "${header['title']}",
                    "url": "https://${this.doc.location.host}${this.doc.location.pathname}"
                  },
                  {
                    "@type": "Organization",
                    "@id": "https://${this.doc.location.host}${this.doc.location.pathname}#organization",
                    "name": "${header['title']}",
                    "url": "https://${this.doc.location.host}${this.doc.location.pathname}",
                    "logo": {
                        "@type": "ImageObject",
                        "inLanguage": "id-ID",
                        "@id": "https://${this.doc.location.host}${this.doc.location.pathname}#/schema/logo/image/",
                        "url": "${header['logo']}",
                        "contentUrl": "${header['logo']}",
                        "width": 1024,
                        "height": 1024,
                        "caption": "${header['title']}"
                    }
                  },
                  {
                    "@type": "Event",
                    "startDate": "${value.updated_at}",
                    "eventStatus": "https://schema.org/EventScheduled",
                    "name": "${value.title}",
                    "description": "${value.title}",
                    "location": [{
                      "@type": "VirtualLocation",
                      "url": "https://${this.doc.location.host}${this.doc.location.pathname}"
                    }],
                    "offers": {
                      "@type": "Offer",
                      "url":"https://${this.doc.location.host}${this.doc.location.pathname}",
                      "availability": "https://schema.org/InStock",
                      "price": "200",
                      "priceCurrency": "IDR"
                    },
                    "image": ["${value.banner_url}"],
                    "organizer": {
                      "@type": "Organization",
                      "name": "${header['title']}"
                    }
                  }
                ]
                  
                }`;
              }
              this.meta.updateTag({ property: 'og:title', content: value.title });
              this.meta.updateTag({ property: 'og:image', content: value.banner_url });
              this.meta.updateTag({ property: 'og:image:secure_url', content: value.banner_url });
            }
          }
        }
        
      }
      
    }
    
    if (next.url[0]?.path === 'play'){
      if(next.params['name']){
        // elementSchema?.remove();
        if (elementSchema) {
          elementSchema.innerHTML =
          `{ 
            "@context": "https://schema.org",
              "@type": "Game",
              "genre": "Slot",
              "description":"",
              "url": "https://${this.doc.location.host}${this.doc.location.pathname}"
        }`;
        // this.doc.head.appendChild(scriptBrand);
        }
      }
    }
    // if (next.queryParams['providers']) {
    //   if(JSON.parse(decodeURIComponent(next.queryParams['providers'])).length === 1){
    //     let slugProvider = new SlugNamePipe();
    //     const isProvider = providerData.find((value: any) => slugProvider.transform(value.code) === JSON.parse(decodeURIComponent(next.queryParams['providers']))[0]);
    //     elementSchema?.remove();
    //     this.doc.head.appendChild(scriptBrand);
    //   }
    // }
    // if (next.queryParams['name']) {
    //   if(JSON.parse(decodeURIComponent(next.queryParams['name'])).length === 1){
    //     let slugProvider = new SlugNamePipe();
    //     const isProvider = providerData.find((value: any) => slugProvider.transform(value.code) === JSON.parse(decodeURIComponent(next.queryParams['name']))[0]);
    //     elementSchema?.remove();
    //     this.doc.head.appendChild(scriptBrand);
    //   }
    // }
    //TEST
    // if (next.queryParams['name'])  {
    //   if(JSON.parse(decodeURIComponent(next.queryParams['name'])).length === 1){
    //     let slugProvider = new SlugNamePipe();
    //     const isProvider = providerData.find((value: any) => slugProvider.transform(value.code) === JSON.parse(decodeURIComponent(next.queryParams['name']))[0]);
    //     elementSchema?.remove();
    //     this.doc.head.appendChild(scriptBrand);
    //   }
    // }
  }

  setTitle(title: string) {
    this.title.setTitle(title);
  }

  setFavicon(favicon: string) {
    // Remove existing favicon if exists
    let existingFavicon = this.doc.querySelector("link[rel='icon']");
    if (existingFavicon) {
      this.doc.head.removeChild(existingFavicon);
    }

    let newFavicon = this.doc.createElement('link');
    newFavicon.setAttribute('rel', 'icon');
    newFavicon.setAttribute('type', 'image/x-icon');
    newFavicon.setAttribute('href', favicon);

    this.doc.head.appendChild(newFavicon);
  }

  domainExtract(url: string): any {
    let dataDomain = url.match(
      /^(?<protocol>https?:\/\/)(?=(?<fqdn>[^:/]+))(?:(?<service>www|ww\d|cdn|ftp|st|mail|pop\d?|ns\d?|git)\.)?(?:(?<subdomain>[^:/]+)\.)*(?<domain>[^:/]+\.[a-z0-9]+)(?::(?<port>\d+))?(?<path>\/[^?]*)?(?:\?(?<query>[^#]*))?(?:#(?<hash>.*))?/i
    )?.groups;
    return dataDomain;
  }

  addCanonicalAMP(listDomain: any) {
    // console.log(this.doc.location.origin)
    // let domainAMP = listDomain.find((x: any) => x['amp'] === true);
    // console.log(domainAMP);
    // if (domainAMP) {
    // ref & referral
    let paramsReferral = '';
    
    let originURL = this.doc.location.hostname;

    // console.log(this.doc.location);
    const urlParams = new URLSearchParams(this.doc.location.search);
    let refParam: string | null = null;
    if (urlParams.get('ref')){
      refParam = urlParams.get('ref');
    }
    if (urlParams.get('referral')){
      refParam = urlParams.get('referral');
    }
    // console.log(refParam)
    if(refParam){
      paramsReferral = '?ref=' + refParam;
    } else {
      if (this.cookies.get('ref')){
        paramsReferral = '?ref=' + this.cookies.get('ref');
      }
    }
    // this.route.queryParams.subscribe(params => {
    //   console.log(params['ref'])
    //   if(params['ref'] || params['referral']){
    //     let currentParamsVal = '';
    //     if(params['ref']){
    //       console.log(params['ref'])
    //       currentParamsVal = params['ref'];
    //     }
    //     if (params['referral']) {
    //       currentParamsVal = params['referral'];
    //     }
    //     paramsReferral = '?ref=' + currentParamsVal;
    //   } else {
    //     console.log('yeaahhh')
    //   }
    // });
    // if (this.cookies.get('ref')){
    //   paramsReferral = '?ref=' + this.cookies.get('ref');
    // }
    let hostClient = 'https://' + originURL.split(/\./).slice(-2).join('.');
    this.doc.querySelector('[rel=amphtml]')?.setAttribute('href', hostClient + '/amp'+paramsReferral);
      // const newCanonicalLink = this.doc.createElement(
      //   'link'
      // ) as HTMLLinkElement;
      // newCanonicalLink.rel = 'amphtml';
      // newCanonicalLink.href = this.doc.location.origin+'/amp';
      // this.doc.head.appendChild(newCanonicalLink);
    // }
  }

  updateCanonicalUrl(defaultCanonical: string, listDomain: Domain[]) {
    let url: string = defaultCanonical;
    // const currentProtocol = window.location.protocol;
    // const currentHostname = window.location.hostname;
    // console.log(this.doc.URL);
    // let currentUrl = `${currentProtocol}//${currentHostname}`;
    let currentUrl = this.doc.URL;
    const domainExtractResult = this.domainExtract(currentUrl);

    if (!domainExtractResult || !domainExtractResult.domain) {
      this.doc.querySelector('[rel=canonical]')?.remove();
      return;
    }

    let currentDomain = domainExtractResult.domain;
    let domainData = listDomain.find(
      (item: Domain) => this.domainExtract(item.domain).domain === currentDomain
    );
    if (domainData) {
      url = domainData.canonical;
    }
    // console.log(url)

    // const canonicalLink: HTMLLinkElement | null = this.doc.querySelector(
    //   'link[rel="canonical"]'
    // );
    // if (canonicalLink) {
    //   canonicalLink.href = url;
    // } else {
    //   const newCanonicalLink = this.doc.createElement(
    //     'link'
    //   ) as HTMLLinkElement;
    //   newCanonicalLink.rel = 'canonical';
    //   newCanonicalLink.href = url;
    //   this.doc.head.appendChild(newCanonicalLink);
    // }
    this.doc.querySelector('[rel=canonical]')?.setAttribute('href', url);
    // const newCanonicalLink = this.doc.createElement(
    //   'link'
    // ) as HTMLLinkElement;
    // newCanonicalLink.rel = 'canonical';
    // newCanonicalLink.href = url;
    // this.doc.head.appendChild(newCanonicalLink);
  }

  addRobotNoIndex(listDomain: Domain[]) {
    // const currentProtocol = window.location.protocol;
    // const currentHostname = window.location.hostname;
    // let currentUrl = `${currentProtocol}//${currentHostname}`;
    let currentUrl = this.doc.URL;
    // let currentUrl = 'https://v5.continue.rest/';

    const domainExtractResult = this.domainExtract(currentUrl);
    // console.log(domainExtractResult)

    if (!domainExtractResult || !domainExtractResult.domain) {
      this.meta.removeTag("name=robots");
      this.meta.removeTag("name=googlebot");
      this.meta.removeTag("name=googlebot-news");
      return;
    }

    let currentDomain = domainExtractResult.domain;

    
    let domainData = listDomain.find(
      (item: Domain) =>
        this.domainExtract(item.domain)?.domain === currentDomain
    );
    // console.log(domainData);

    if (!domainData) {
      this.meta.removeTag("name=robots");
      this.meta.removeTag("name=googlebot");
      this.meta.removeTag("name=googlebot-news");
      return;
    }

    if (domainData.robot_no_index) {
      this.meta.updateTag({ name: 'robots', content: 'noindex' });
      this.meta.updateTag({ name: 'googlebot', content: 'noindex' });
      this.meta.updateTag({ name: 'googlebot-news', content: 'noindex' });
    } else{
      this.meta.removeTag("name=robots");
      this.meta.removeTag("name=googlebot");
      this.meta.removeTag("name=googlebot-news");
      return;
    }
  }
}

// @Injectable({
//   providedIn: 'root'
// })

// class MetaWebsiteService {

//   constructor(
//     @Inject(PLATFORM_ID) private platformId: Object,
//     private router: Router,
//     private dataService: DataService,
//     private auth: AuthService,
//     private title: Title
//   ) { }

//   getMeta(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
//     this.title.setTitle('BAHAGIA');
//   }

// }

export const MetaWebsiteService: ResolveFn<any> = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => {
  return inject(MetaService).setMetaWebsite(next, state);
}