import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Input,
  PLATFORM_ID,
  Inject,
  CUSTOM_ELEMENTS_SCHEMA,
  afterNextRender,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  faMagnifyingGlass,
  faAnglesRight,
  faCircleChevronRight,
  faCircleChevronLeft,
  faCirclePlay,
} from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CardGameComponent } from '../card-game/card-game.component';
import { RouterModule } from '@angular/router';
import { db } from '@componentpath/db/db';
import { RequestService } from '@componentpath/services/request.service';
import {
  NgbDropdownModule,
  NgbCollapseModule,
} from '@ng-bootstrap/ng-bootstrap';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { register } from 'swiper/element/bundle';
import { SwiperOptions } from 'swiper/types';
import { A11y, Grid, Mousewheel, Navigation, Pagination } from 'swiper/modules';

@Component({
  selector: 'app-all-games-home',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    CardGameComponent,
    NgbDropdownModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    FormsModule,
    NgbCollapseModule,
  ],
  templateUrl: './all-games-home.component.html',
  styleUrl: './all-games-home.component.scss',
})
export class AllGamesHomeComponent {
  @ViewChild('swiperHomeGame') swiperHomeGame!: ElementRef;
  @ViewChild('scrollAnchor', { static: false }) scrollAnchor!: ElementRef;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private request: RequestService,
    private router: Router
  ) {
    this.swiperRender();
  }

  @Input() loadMore: boolean = false;
  @Input() minHeight: boolean = false;

  @Input() page: number = 1;
  @Input() perPage: number = 24;
  @Input() tags: Array<any> = [''];
  @Input() data: Array<any> = [];
  
  types: Array<any> = [''];
  currentID : string = '1';
  totalPages: number = 0;
  tagLists: Array<any> = [
    {
      name: 'POPULAR SLOT',
      type: 'SL',
      id:'1'
    },
    {
      name: 'NEW',
      type: 'NEW',
      id:'2'
    },
    {
      name: 'CRASH GAMES',
      type: 'CRASH',
      id:'3'
    },
    {
      name: 'LIVE GAME SHOWS',
      type: 'GAMESHOW',
      id:'4'
    },
    {
      name: 'ARCADE',
      type: 'FH',
      id:'5'
    },
    {
      name: 'SPORTBOOK',
      type: 'SB',
      id:'6'
    },
    {
      name: 'ESPORT',
      type: 'ES',
      id:'7'
    },
    {
      name: 'JACKPOT SLOTS',
      type: 'JACKPOT',
      id:'8'
    },
    {
      name: 'BONANZA GAMES',
      type: 'BONANZA',
      id:'9'
    },
  ];

  faMagnifyingGlass: IconProp = faMagnifyingGlass;
  faAnglesRight: IconProp = faAnglesRight;
  faCircleChevronRight: IconProp = faCircleChevronRight;
  faCircleChevronLeft: IconProp = faCircleChevronLeft;
  faCirclePlay: IconProp = faCirclePlay;

  selectedOptions: any[] = [];
  searchInput: string = '';
  preload: boolean = true;
  finished: boolean = false;

  public configSwiper: SwiperOptions = {};

  goToList(){
    this.router.navigate(['/search']);
  }

  filterGames(code: string, id: string) {
    let type = [''];
    this.tags = [''];
    this.page = 1;
    if(
      code === "CRASH" ||
      code === "GAMESHOW" ||
      code === "JACKPOT" ||
      code === "NEW" ||
      code === "BONANZA"
    ){
      this.tags = [code];
      if(code === "JACKPOT"){
        type = ['SL']
      }
    }
    if(
      code === "SL" ||
      code === "FH" ||
      code === "ES" ||
      code === "SB" 
    ) {
      this.tags = ['']
      type = [code]
    }
    this.currentID = id;
    this.types = type;
    return this.getGames(true, type);
  }

  swiperRender() {
    afterNextRender((): void => {
      this.configSwiper = {
        loop: false,
        mousewheel: true,
        slideToClickedSlide: true,
        slidesPerView: 'auto',
        freeMode: true,
        pagination: false,
        navigation: {
          nextEl:'.swiper-button-next',
          prevEl:'.swiper-button-prev'
        },
        on: {
          init() {
            // Here you can add some functions after initializing the swiper
          },
        },
      };

      Object.assign(this.swiperHomeGame.nativeElement, this.configSwiper); // Add parameters to the Swiper
      this.swiperHomeGame.nativeElement.initialize(); // Init Swiper
    });
  }

  searchItem() {
    // console.log(this.types);
    if (this.searchInput) {
      if(this.tags[0] !== '' && this.types[0] === ''){
        this.router.navigate(['/search'], {queryParams: { "tags":encodeURIComponent(JSON.stringify(this.tags)), "value": this.searchInput }});
      }
      if(this.types[0] !== '' && this.tags[0] === ''){
        this.router.navigate(['/search'], {queryParams: { "categories":encodeURIComponent(JSON.stringify(this.types)), "value": this.searchInput }});
      }
      if(this.tags[0] === '' && this.types[0] === ''){
        this.router.navigate(['/search'], {queryParams: {"value": this.searchInput }});
      }
      if (this.tags[0] !== '' && this.types[0] !== ''){
        this.router.navigate(['/search'], { queryParams: { "tags": encodeURIComponent(JSON.stringify(this.tags)), "categories": encodeURIComponent(JSON.stringify(this.types)),  "value": this.searchInput }});
      }
    }
  }

  getGames(reset: boolean = false, type:Array<any>) {
    this.finished = false;
    if(type.length === 0){
      type = ['']
    }
    if (isPlatformBrowser(this.platformId)) {
      this.request
        .gamesJSON(
          this.page,
          this.perPage,
          [''],
          type,
          this.tags,
          this.searchInput,
          'popularity_point',
          'desc'
        )
        .subscribe({
          next: (v) => {
            if (reset) {
              this.data = v['items'];
            } else {
              this.data = this.data.concat(v['items']);
            }
            this.totalPages = v['totalPages'];
            // db.addGames(v['items']);
          },
          error: (e) => {
            console.log(e);
          },
          complete: () => {
            this.preload = false;
            this.finished = true;
          },
        });
    }
  }

  filterSearchChange(event: any) {
    this.page = 1;
    this.searchInput = event.target.value;
    this.getGames(true, this.types);
  }

  loadMoreClick() {
    this.page = this.page + 1;

    this.getGames(false, this.types);
  }

  loadMoreAuto() {
    this.page = this.page + 1;
    console.log(this.page)
    if(this.page >= 4){
    }else{
      this.getGames(false, this.types);
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.data.length === 0) {
        // this.getGames(true, this.types);
        this.filterGames('SL', '1')
      }
    }

  }
  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const observer = new IntersectionObserver(
        (entries) => {
          setTimeout(() => {
            this.loadMoreAuto();
          }, 2000);
        },
        { threshold: 1 }
      );

      observer.observe(this.scrollAnchor.nativeElement);
    }
    register();
  }
}
