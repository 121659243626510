import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, NgZone, PLATFORM_ID, inject, model, signal } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { DataService } from '@componentpath/services/data.service';
import { RequestService } from '@componentpath/services/request.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { AuthService } from '@componentpath/services/auth.service';
import { Subscription } from 'rxjs';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MetaService } from '@componentpath/services/meta.service';
import { RouterExtService, ScriptsService } from '@componentpath/services/scripts.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from '@template/green/parts/popup/popup.component';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [CommonModule, RouterOutlet, NgbModule],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent {
    title = 'orianna';
    bindingData: any;
    someInterval: any;
    subscription: Subscription = new Subscription();
    islogin: boolean = false;
    loaded: boolean = false;
    showSplash: boolean = false;
    websiteJSONData: any;
    currentScreenSize: string = '';
    currentVarURL:string ='';

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private zone: NgZone,
        private data: DataService,
        private request: RequestService,
        private auth: AuthService,
        private cookie: SsrCookieService,
        private metaService: MetaService,
        private scriptsService: ScriptsService,
        private breakpointObserver: BreakpointObserver,
        private routerExtService: RouterExtService,
        public router: Router,
        public route: ActivatedRoute,
    ) {
        this.routerExtService.getPreviousUrl();
        this.checkScreen();
    }

    checkScreen() {
        let displayNameMap = new Map([
            [Breakpoints.XSmall, 'xSmall'],
            [Breakpoints.Small, 'small'],
            ["(min-width: 960px) and (max-width: 1365.98px)", 'customTablet'],
        ]);
        this.breakpointObserver.observe([
            Breakpoints.XSmall,
            Breakpoints.Small,
            "(min-width: 960px) and (max-width: 1365.98px)",
            "(min-width: 1366px)"
        ]).subscribe(result => {
            // console.log(result)
            for (const query of Object.keys(result.breakpoints)) {
                if (result.breakpoints[query]) {
                    this.currentScreenSize = displayNameMap.get(query) ?? 'desktop';
                }
            }
            // console.log(this.currentVarURL);
            if (this.currentScreenSize === 'xSmall') {
                if(this.data.websiteJSONData['contact']){
                    this.scriptsService.hideChat(this.data.websiteJSONData['contact']);
                }

                if (this.currentVarURL === '/' || this.currentVarURL.search('/provider') === 0 || this.currentVarURL.search('/game') === 0 || this.currentVarURL.search('/real-money') === 0) {
                    if (this.currentVarURL.search('/providers') === 0 || this.currentVarURL === '/') {
                        this.scriptsService.displayCustomScript();
                    } else {
                        this.scriptsService.removeCustomScript();
                    }
                } else {
                    this.scriptsService.removeCustomScript();
                }
                
            } else {

                if (this.currentVarURL.search('/play') === 0 || this.currentVarURL.search('/register') === 0 || this.currentVarURL.search('/login') === 0) {
                    this.scriptsService.removeCustomScript();
                    if (this.data.websiteJSONData['contact']) {
                        this.scriptsService.hideChat(this.data.websiteJSONData['contact']);
                    }
                    
                } else {
                    this.scriptsService.displayCustomScript();
                    if (this.data.websiteJSONData['contact']) {
                        if (isPlatformBrowser(this.platformId)) {
                            this.scriptsService.showChat(this.data.websiteJSONData['contact']);
                        }
                    }
                }
                
            }
        });
    }

    readonly dialog = inject(MatDialog);
    openDialog(): void {
        // console.log(this.data.popupsJSONData);
        // console.log(popupFilterKeyword);
        if(!this.cookie.get('popup001') && this.data.popupsJSONData){
            // let popupFilter = (this.data.popupsJSONData as Array<any>).filter((li: any) => !(li.keyword.length === 1 && li.keyword.includes('GENERAL')));
            let arraypopups = this.data.popupsJSONData as Array<any>;
            if(arraypopups.length > 0){
                const dialogRef = this.dialog.open(PopupComponent, {
                    data: { records: arraypopups },
                    width: '95vw',
                    maxWidth:'98vw'
                });
        
                dialogRef.afterClosed().subscribe(result => {
                    // console.log('The dialog was closed');
                    // let currDate = new Date();
                    // currDate.setTime(currDate.getTime() + 600000);
                    this.cookie.set('popup001', '1', { expires: 1 });
                    // if (result !== undefined) {
                    //     this.animal.set(result);
                    // }
                });
            }
        }
    }

    private modalService = inject(NgbModal);
    url: string = '';

    close() {
        this.modalService.dismissAll();
    }

    getRouterEvents(){
        const routerEvents = this.router.events.subscribe((val: any) => {
            if (val instanceof NavigationEnd) {
                this.currentVarURL = val.url;
                // console.log(val.url)
                if (this.currentScreenSize !== 'xSmall') {
                    if (val.url.search('/play') === 0 || val.url.search('/register') === 0 || val.url.search('/login') === 0) {
                        if (isPlatformBrowser(this.platformId)) {
                            this.scriptsService.hideChat(this.data.websiteJSONData['contact']);
                        }
                    } else {
                        if (isPlatformBrowser(this.platformId)) {
                            this.scriptsService.showChat(this.data.websiteJSONData['contact']);              
                        }
                    }

                    if (val.url.search('/play') === 0 || val.url.search('/register') === 0 || val.url.search('/login') === 0) {
                        this.scriptsService.removeCustomScript();
                    } else {
                        this.scriptsService.displayCustomScript();
                    }
                }
                if (this.currentScreenSize === 'xSmall') {
                    if (val.url === '/' || val.url.search('/provider') === 0 || val.url.search('/game') === 0 || val.url.search('/real-money') === 0) {
                        if (val.url.search('/providers') === 0 || val.url === '/'){
                            this.scriptsService.displayCustomScript();
                        }else{
                            this.scriptsService.removeCustomScript();
                        }
                    } else {
                        this.scriptsService.removeCustomScript();
                    }
                }


                if (val.url.search('/play') !== 0 && val.url.search('/login') !== 0 && val.url.search('/register') !== 0 && val.url.search('/deposit') !== 0 && val.url.search('/withdraw') !== 0 && val.url.search('/pin') !== 0) {
                    // console.log(val.url.search('/register'))
                    if (isPlatformBrowser(this.platformId)) {
                        this.openDialog();
                    }
                }
            }
        })
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            if (params['source']) {
                if ((params['source']).toLowerCase() === 'apk'){
                    this.cookie.set('source', 'apk');
                }
                if ((params['source']).toLowerCase() === 'desktop') {
                    this.cookie.set('source', 'desktop');
                }
                if ((params['source']).toLowerCase() === 'mobile') {
                    this.cookie.set('source', 'mobile');
                }
            }
        });
        this.auth.updateLoginStatus();
        this.data.getFileJSON();
        const routerEventsSplash = this.router.events.subscribe((val: any) => {
            // console.log(val.url)
            if (val instanceof NavigationEnd){
                if (val.url === '/') {
                    this.showSplash = true;
                } else {
                    this.showSplash = true;
                }
                routerEventsSplash.unsubscribe(); //trigger splash screen just once.
            }

        })
        this.getRouterEvents();
        
        // console.log(this.data.categoryslideshowJSONData)
        if (isPlatformBrowser(this.platformId)) {
            this.websiteJSONData = this.data.websiteJSONData;
            
            // document.addEventListener('contextmenu', event => event.preventDefault()); //disable right click
            // this.metaService.setMetaWebsite();
            this.scriptsService.createGoogleScript(this.data.websiteJSONData['script']['thirdparty']['google_analytic']);
            this.scriptsService.createMetaScript(this.data.websiteJSONData['script']['thirdparty']['facebook_id']);
            this.scriptsService.createKwaiScript(this.data.websiteJSONData['script']['thirdparty']['kwai_id']);
            this.scriptsService.createBigoScript(this.data.websiteJSONData['script']['thirdparty']['bigo_id']);
            //chats
            this.scriptsService.createLivechat(this.data.websiteJSONData['contact']);
            this.scriptsService.createPureChat(this.data.websiteJSONData['contact']);
            this.scriptsService.createTawkto(this.data.websiteJSONData['contact']);

            if(this.currentScreenSize === "xSmall"){
                if (this.data.websiteJSONData['contact']) {
                    this.scriptsService.hideChat(this.data.websiteJSONData['contact']);
                }
            }
            
            // this.scriptsService.createLivechat(this.data.websiteJSONData['contact']);
            // setTimeout(() => {
            //     this.scriptsService.removeChat(this.data.websiteJSONData['contact']);
            // }, 15000);
        
            this.auth.getUserData();
            if (this.auth.sessionValue){
                this.auth.callSession(this.auth.sessionValue);
            }
            this.route.queryParams.subscribe(values => {
                if(values['ref']){
                    this.cookie.set('ref', values['ref'], this.data.websiteConfigData.cookie_options);
                }
            });
            // console.log(this.route.snapshot.queryParams)
        }
    }
    ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.scriptsService.displayCustomScript();
        }
    }
    ngOnDestroy(): void {
        if (isPlatformBrowser(this.platformId)) {
            // this.subscription.unsubscribe();
        }
    }
}
