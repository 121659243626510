<div>
  <div
    class=" d-md-block all-games"
    [ngClass]="{ 'min-height': minHeight === true }"
  >
    <div class="d-flex align-items-start filter-new">
      <div class="container-xxl m-auto">
        <div class="w-100 d-flex align-items-center justify-space-between">
          <!-- <swiper-container
          appSwiper
          [config]="config"
          class="swiper-container"
          > -->
          <div class="button-slider">
            <div class="swiper-button-prev mr-1">
              <fa-icon [icon]="faCirclePlay"></fa-icon>
            </div>

            <swiper-container init="false" #swiperHomeGame class="swiper-container">
              @for (item of tagLists; track item;) {
              <swiper-slide class="swiper-item w-auto">
                <button [class.active]="item.id === currentID" (click)="filterGames(item.type, item.id)" class="button-swiper-all-games">
                  {{ item.name }}
                </button>
              </swiper-slide>
              }
            </swiper-container>

            <div class="swiper-button-next ml-1">
              <fa-icon [icon]="faCirclePlay"></fa-icon>
            </div>
          </div>
          <!-- </swiper-container> -->
          <form (keydown.enter)="searchItem()">
            <input
              type="text"
              class="form-control search-bar-home"
              placeholder="Search Games.."
              [ngModelOptions]="{standalone: true}"
              [(ngModel)]="searchInput"
              (keyup.enter)="filterSearchChange($event)"
              aria-label="Search Games.."
            />
            <button type="button" class="btn" (click)="searchItem()">
              <fa-icon [icon]="faMagnifyingGlass" type="submit"></fa-icon>
            </button>
          </form>
        </div>
      </div>
    </div>
    <div class="container-xxl m-auto all-games-inner">
      @if(preload){ @for (item of [].constructor(10); track item;) {
      <app-card-game [isLoading]="true" />
      } } @else { @for (item of data; track item.id;) {
      <app-card-game
        [info]="item"
        [image]="item.image"
        [gacor]="item.gacor"
        [favourite]="item.favourite"
        [name]="item.title"
        [providername]="item.provider_name"
        [percent]="item.rtp"
        [pattern]="item.pattern"
        [dc]="item.dc"
      />
      } }
    </div>
    @if (!finished) {
      <div class="loader-spinner">
        <mat-spinner diameter="50"></mat-spinner>
      </div>
    }
    <div #scrollAnchor class="scroll-anchor"></div>
    @if (page >= 4 || page === totalPages || totalPages === 0) {
      <div class="container-xxl m-auto load-more text-center" (click)="goToList()">
        LIHAT SEMUA GAMES
      </div>
    }
    @if(loadMore === true && page < totalPages) {
    <div
      class="container-xxl m-auto load-more text-center"
      (click)="loadMoreClick()"
    >
      LOAD MORE <fa-icon [icon]="faAnglesRight"></fa-icon>
    </div>
    }
  </div>
</div>
