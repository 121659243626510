import { CUSTOM_ELEMENTS_SCHEMA, Component, Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule, CurrencyPipe, isPlatformBrowser } from '@angular/common';
import { DataService } from '@componentpath/services/data.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DepositMobileComponent } from '@template/green/mobile/deposit-mobile/deposit-mobile.component';
import { HeaderSimpleComponent } from '@template/green/header-simple/header-simple.component';
import { DepositDesktopComponent } from '@template/green//parts/deposit-desktop/deposit-desktop.component';

@Component({
  selector: 'app-deposit',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, DepositDesktopComponent, DepositMobileComponent],
  templateUrl: './deposit.component.html',
  styleUrl: './deposit.component.scss'
})
export class DepositComponent {

  currentScreenSize: string = '';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.checkScreen();
  }

  checkScreen() {
    let displayNameMap = new Map([
      [Breakpoints.XSmall, 'xSmall'],
      ["(min-width: 600px) and (max-width: 799.98px)", 'smallOne'],
      ["(min-width: 800px) and (max-width: 959.98px)", 'smallTwo'],
      ["(min-width: 960px) and (max-width: 1365.98px)", 'customTablet'],
    ]);
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      "(min-width: 600px) and (max-width: 799.98px)",
      "(min-width: 800px) and (max-width: 959.98px)",
      "(min-width: 960px) and (max-width: 1365.98px)",
      "(min-width: 1366px)"
    ]).subscribe(result => {
      // console.log(result)
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this.currentScreenSize = displayNameMap.get(query) ?? 'desktop';
        }
      }
    });
  }
}
