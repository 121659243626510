import {
  Component,
  OnInit,
  CUSTOM_ELEMENTS_SCHEMA,
  PLATFORM_ID,
  Inject,
  Input,
  afterNextRender,
  ElementRef,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { CommonModule, isPlatformBrowser, NgOptimizedImage } from '@angular/common';
import { SwiperOptions } from 'swiper/types';
import { A11y, Autoplay, Grid, Mousewheel, Navigation, Pagination } from 'swiper/modules';
import { SlugNamePipe } from '@componentpath/pipes/slug-name.pipe';
import { DataService } from '@componentpath/services/data.service';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Router, RouterModule } from '@angular/router';
import { register } from 'swiper/element/bundle';

@Component({
  selector: 'app-list-providers',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, RouterModule, SlugNamePipe, NgOptimizedImage, FontAwesomeModule],
  templateUrl: './list-providers.component.html',
  styleUrl: './list-providers.component.scss',
})
export class ListProvidersComponent implements OnInit, AfterViewInit {

  @Input() data: any;
  @Input() category: any;
  @ViewChild('swiperProviders') swiperProviders!: ElementRef;

  websiteJSONData: any = this.dataService.websiteJSONData;
  faArrowRight: IconProp = faArrowRight;

  public configSwiperProvider: SwiperOptions = { };
  providerList: any;
  categoryProviderList: any;
  currentCat: any;
  currentCatCode: string = '';
  currentCatTag: string = '';
  limit: number = 9;
  
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private dataService: DataService,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {
    this.swiperRender();
  }

  gotoProvider(){
    // this.router.navigate(['/providers']);
    if(this.limit <= 9){
      this.limit = 100;
    } else {
      this.limit = 9;
    }
  }

  swiperRender() {
    afterNextRender((): void => {
      this.configSwiperProvider = {
        loop: false,
        scrollbar: false,
        mousewheel: true,
        slidesPerView: 3,
        slidesPerGroup: 3,
        grid: {
          fill: 'column',
          rows: 3,
        },
        spaceBetween: 10,
        freeMode: false,
        pagination: false,
        navigation: false,
        on: {
          init() {
            // Here you can add some functions after initializing the swiper
          },
        },
      };
      // this.configSwiperProvider = {
      //   mousewheel: true,
      //   loop: true,
      //   modules:[Autoplay],
      //   speed: 1500,
      //   slidesPerView: 'auto',
      //   autoplay: {
      //     delay: 5000,
      //     disableOnInteraction: false,
      //   },
      //   spaceBetween: 10,
      //   freeMode: true,
      //   pagination: false,
      //   navigation: false,
      //   on: {
      //     init() {},
      //   },
      // };
      Object.assign(this.swiperProviders.nativeElement, this.configSwiperProvider); // Add parameters to the Swiper
      this.swiperProviders.nativeElement.initialize(); // Init Swiper
    })
  }

  clickMenu(cat: string) {
    console.log(this.categoryProviderList);
    this.currentCat = this.categoryProviderList.find((x:any) => x.code === cat);
    if(this.currentCat){
      this.currentCatCode = cat;
      this.currentCatTag = this.currentCat['tag'].toLowerCase();
    if(this.currentCatTag === 'casino'){
      this.currentCatTag = 'livecasino';
    }
      this.providerList = this.currentCat.providers;
      this.providerList.sort((a: any, b: any) => parseFloat(a['priority']) - parseFloat(b['priority']) || a["name"].localeCompare(b["name"]));
    }else{
      this.currentCatCode = 'all';
      this.currentCatTag = '';
      this.providerList = this.data.records;
      this.providerList.sort((a: any, b: any) => parseFloat(a['priority']) - parseFloat(b['priority']) || a["name"].localeCompare(b["name"]));
    }
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      // this.bankList = this.data.filter((li: any) => li.type === 1);
      this.providerList = this.data.records;
      this.categoryProviderList = this.category;
      this.cdr.detectChanges();
    }
  }
  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      register();
    }
  }
}
